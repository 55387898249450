import "./App.css";
import React, { useState } from "react";
import Button from "./components/Button";
import NewQuestionForm from "./components/NewQuestionForm";
import NewResponseForm from "./components/NewResponseForm";
import FilteredTable from "./components/Table2";
import ResponseTable from "./components/ResponseTable";
import Amplify from "aws-amplify";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { API } from "aws-amplify";
import { byNewStatus } from "./graphql/queries";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
Amplify.configure({
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
});

const blankQuestion = {
  question: "",
  correctAnswer: "",
  incorrectAnswer1: "",
  incorrectAnswer2: "",
  incorrectAnswer3: "",
  difficulty: "easy",
  category: "",
  expirationDate: new Date(2050, 1),
};

const blankResponse = {
  type: "",
  percent: "",
  text: "",
};

function App() {
  const [newQuestion, setNewQuestion] = useState(blankQuestion);
  const [newResponse, setNewResponse] = useState(blankResponse);
  const [page, setPage] = useState("questions");
  const [view, setView] = useState("addQuestion");
  async function getQuestion() {
    // const source = Math.random() > 0.5 ? "new_triviadb" : "new_csv";
    setView("addQuestion");
    const source = "new_csv";
    const newQuestions = await API.graphql({
      query: byNewStatus,
      variables: { status: source },
    });
    // console.log(newQuestions.data.byNewStatus.items);
    setNewQuestion(newQuestions.data.byNewStatus.items[0]);
  }
  function addNewQuestion() {
    setNewQuestion(blankQuestion);
    setView("addQuestion");
  }

  function changePageView(page, view) {
    setPage(page);
    setView(view);
  }

  function loadQuestion(question, view) {
    setNewQuestion(question);
    console.log("loadQuestion function", view);
    if (view === "stash") {
      setView("addQuestion");
    } else {
      setView("editQuestion");
    }
  }

  function addNewResponse() {
    setNewResponse(blankResponse);
    setView("addResponse");
  }

  function loadResponse(response, view) {
    setNewResponse(response);
    setView("editResponse");
  }

  console.log(newQuestion);
  return (
    <div className="App">
      <div className="nav">
        <div className="nav-buttons">
          <button
            class="page-button"
            onClick={() => changePageView("questions", "addQuestion")}
          >
            Questions
          </button>
          <button
            class="page-button"
            onClick={() => changePageView("responses", "addResponse")}
          >
            Responses
          </button>
        </div>
        <div className="nav-logout">
          <AmplifySignOut />
        </div>
      </div>
      {page === "questions" && (
        <div className="question-components">
          <div className="buttons">
            <Button onAction={addNewQuestion} buttonText="New Question" />
            <Button onAction={getQuestion} buttonText="Get Question" />
            <Button
              onAction={() => setView("table")}
              buttonText="Search Questions"
            />
            <Button
              onAction={() => setView("stash")}
              buttonText="Search Stash"
            />
          </div>
          {(view === "addQuestion" || view === "editQuestion") && (
            <NewQuestionForm newQuestion={newQuestion} view={view} />
          )}
          {view === "table" && (
            <FilteredTable loadQuestion={loadQuestion} view={view} />
          )}
          {view === "stash" && (
            <FilteredTable loadQuestion={loadQuestion} view={view} />
          )}
        </div>
      )}
      {page === "responses" && (
        <div className="responses-components">
          <div className="buttons">
            <Button onAction={addNewResponse} buttonText="New Response" />
            <Button
              onAction={() => setView("responseTable")}
              buttonText="Search Responses"
            />
          </div>
          {(view === "addResponse" || view === "editResponse") && (
            <NewResponseForm view={view} newResponse={newResponse} />
          )}
          {view === "responseTable" && (
            <ResponseTable view={view} loadResponse={loadResponse} />
          )}
        </div>
      )}
    </div>
  );
}

export default withAuthenticator(App);
