import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import "./NewResponseForm.css";
import {
  createResponse,
  updateResponse,
  deleteResponse,
} from "../graphql/mutations";

async function updateResponseDetails(response) {
  await API.graphql(
    graphqlOperation(updateResponse, {
      input: response,
    })
  );
}

async function deleteResponseID(response) {
  await API.graphql(
    graphqlOperation(deleteResponse, {
      input: response,
    })
  );
}

const responseTypes = ["result", "share"];
const scores = ["0", "1-2", "3-4", "5-6", "7-8", "9", "10"];

function NewResponseForm({ view, newResponse }) {
  console.log("new response", newResponse);
  const [responseType, setResponseType] = useState("");
  const [score, setScore] = useState("");
  const [response, setResponse] = useState("");

  useEffect(() => {
    setResponseType(newResponse.type);
    setScore(newResponse.percent);
    setResponse(newResponse.text);
  }, [newResponse]);

  const clearResponse = () => {
    setResponse("");
    setScore("");
    setResponse("");
  };

  async function submitResponse() {
    const responseDetails = {
      percent: score,
      type: responseType,
      text: response,
    };
    await API.graphql(
      graphqlOperation(createResponse, {
        input: responseDetails,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
    );
    clearResponse();
  }

  function saveResponse() {
    const responseDetails = {
      id: newResponse.id,
      percent: score,
      type: responseType,
      text: response,
    };
    updateResponseDetails(responseDetails);
    clearResponse();
  }

  function deleteResponse() {
    const responseDetails = {
      id: newResponse.id,
    };
    deleteResponseID(responseDetails);
    clearResponse();
  }

  return (
    <div className="responseForm">
      <div className="input-box-response">
        <div className="input-label-response">Type</div>
        <select
          className="response-select"
          value={responseType}
          id="responseType"
          onChange={(e) => setResponseType(e.target.value)}
        >
          <option />
          {responseTypes.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
      <div className="input-box-response">
        <div className="input-label-response">Score</div>
        <select
          className="response-select"
          value={score}
          id="score"
          onChange={(e) => setScore(e.target.value)}
        >
          <option />
          {scores.map((item) => (
            <option value={item}>{item}</option>
          ))}
        </select>
      </div>
      <div className="input-box question">
        <div className="input-label">Text</div>
        <input
          type="text"
          onChange={(e) => setResponse(e.target.value)}
          value={response}
        />
      </div>
      {view === "addResponse" ? (
        <div className="input-box response-bottom-buttons">
          <button className="form-btn" type="button" onClick={submitResponse}>
            Submit
          </button>
        </div>
      ) : (
        <div className="input-box response-bottom-buttons">
          <button className="form-btn" type="button" onClick={saveResponse}>
            Save
          </button>
          <button className="form-btn" type="button" onClick={deleteResponse}>
            Delete
          </button>
        </div>
      )}
    </div>
  );
}

export default NewResponseForm;
