import { button } from "aws-amplify";
import React from "react";
import "./Button.css";

function Button({ onAction, buttonText }) {
  return (
    <button className="btn" onClick={onAction}>
      {buttonText}
    </button>
  );
}

export default Button;
