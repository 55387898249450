/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      expirationDate
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      expirationDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      expirationDate
      createdAt
      updatedAt
    }
  }
`;
export const createNewQuestion = /* GraphQL */ `
  mutation CreateNewQuestion(
    $input: CreateNewQuestionInput!
    $condition: ModelNewQuestionConditionInput
  ) {
    createNewQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      status
      source
      createdAt
      updatedAt
    }
  }
`;
export const updateNewQuestion = /* GraphQL */ `
  mutation UpdateNewQuestion(
    $input: UpdateNewQuestionInput!
    $condition: ModelNewQuestionConditionInput
  ) {
    updateNewQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      status
      source
      createdAt
      updatedAt
    }
  }
`;
export const deleteNewQuestion = /* GraphQL */ `
  mutation DeleteNewQuestion(
    $input: DeleteNewQuestionInput!
    $condition: ModelNewQuestionConditionInput
  ) {
    deleteNewQuestion(input: $input, condition: $condition) {
      id
      question
      correctAnswer
      incorrectAnswer1
      incorrectAnswer2
      incorrectAnswer3
      difficulty
      category
      status
      source
      createdAt
      updatedAt
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      percent
      type
      text
      createdAt
      updatedAt
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      percent
      type
      text
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      percent
      type
      text
      createdAt
      updatedAt
    }
  }
`;
