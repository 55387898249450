import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  createQuestion,
  updateNewQuestion,
  deleteQuestion,
  updateQuestion,
  createNewQuestion,
} from "../graphql/mutations";
import "./NewQuestionForm.css";

const categories = [
  "geography",
  "entertainment",
  "history",
  "arts-literature",
  "science-nature",
  "sports-leisure",
];
const difficulties = ["easy", "medium", "hard", "genius"];

async function setQuestionStatus(status, id) {
  await API.graphql(
    graphqlOperation(updateNewQuestion, {
      input: { id: id, status: status },
    })
  );
}

async function updateQuestionDetails(question) {
  await API.graphql(
    graphqlOperation(updateQuestion, {
      input: question,
    })
  );
}

async function deleteQuestionID(question) {
  await API.graphql(
    graphqlOperation(deleteQuestion, {
      input: question,
    })
  );
}

function NewQuestionForm({ newQuestion, view }) {
  const [question, setQuestion] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [incorrectAnswer1, setIncorrectAnswer1] = useState("");
  const [incorrectAnswer2, setIncorrectAnswer2] = useState("");
  const [incorrectAnswer3, setIncorrectAnswer3] = useState("");
  const [difficulty, setDifficulty] = useState("easy");
  const [category, setCategory] = useState("");
  const [expiration, setExpiration] = useState(false);
  const [expirationDate, setExpirationDate] = useState(new Date(2050, 1));

  useEffect(() => {
    setQuestion(newQuestion.question);
    setCorrectAnswer(newQuestion.correctAnswer);
    setIncorrectAnswer1(newQuestion.incorrectAnswer1);
    setIncorrectAnswer2(newQuestion.incorrectAnswer2);
    setIncorrectAnswer3(newQuestion.incorrectAnswer3);
    setDifficulty(newQuestion.difficulty);
    let newCategory = newQuestion.category || "";
    setCategory(newCategory);
  }, [newQuestion]);

  //   #TOD - CLear the question here
  const clearQuestion = () => {
    setQuestion("");
    setCorrectAnswer("");
    setIncorrectAnswer1("");
    setIncorrectAnswer2("");
    setIncorrectAnswer3("");
    setDifficulty("easy");
    setCategory("");
    setExpiration(false);
    setExpirationDate(new Date(2050, 1));
  };

  const toggleExpiration = () => {
    if (expiration) {
      setExpirationDate(new Date(2050, 1));
    }
    setExpiration(!expiration);
  };

  const updateExpiration = (e) => {
    setExpirationDate(new Date(e.target.value));
  };

  const rejectQuestion = () => {
    setQuestionStatus("reject", newQuestion.id);
    clearQuestion();
  };

  const updateQuestion = () => {
    const questionDetails = {
      id: newQuestion.id,
      category: category,
      correctAnswer: correctAnswer,
      difficulty: difficulty,
      expirationDate: expirationDate,
      incorrectAnswer1: incorrectAnswer1,
      incorrectAnswer2: incorrectAnswer2,
      incorrectAnswer3: incorrectAnswer3,
      question: question,
    };
    updateQuestionDetails(questionDetails);
    clearQuestion();
  };

  const deleteQuestion = () => {
    const questionDetails = {
      id: newQuestion.id,
    };
    deleteQuestionID(questionDetails);
    clearQuestion();
  };

  async function submitQuestion(e) {
    e.preventDefault();
    const questionDetails = {
      question: question,
      correctAnswer: correctAnswer,
      incorrectAnswer1: incorrectAnswer1,
      incorrectAnswer2: incorrectAnswer2,
      incorrectAnswer3: incorrectAnswer3,
      difficulty: difficulty,
      category: category,
      expirationDate: expirationDate,
    };
    await API.graphql(
      graphqlOperation(createQuestion, {
        input: questionDetails,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })
    );
    newQuestion.id
      ? setQuestionStatus("added", newQuestion.id)
      : console.log("new question");
    clearQuestion();
  }

  async function stashQuestion(e) {
    if (newQuestion.id) {
      const questionDetails = {
        question: question,
        correctAnswer: correctAnswer,
        incorrectAnswer1: incorrectAnswer1,
        incorrectAnswer2: incorrectAnswer2,
        incorrectAnswer3: incorrectAnswer3,
        difficulty: difficulty,
        category: category,
        id: newQuestion.id,
      };
      console.log("from API:", questionDetails);
      await API.graphql(
        graphqlOperation(updateNewQuestion, {
          input: questionDetails,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      );
      setQuestionStatus("stash", newQuestion.id);
    } else {
      const questionDetails = {
        question: question,
        correctAnswer: correctAnswer,
        incorrectAnswer1: incorrectAnswer1,
        incorrectAnswer2: incorrectAnswer2,
        incorrectAnswer3: incorrectAnswer3,
        difficulty: difficulty,
        category: category,
        status: "stash",
        source: "stash",
      };
      console.log("from New:", questionDetails);
      await API.graphql(
        graphqlOperation(createNewQuestion, {
          input: questionDetails,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      );
    }
    console.log("here I am");
    clearQuestion();
  }
  console.log("NewQuestionFom", view);

  return (
    <>
      <form onSubmit={submitQuestion}>
        <div className="input-box question">
          <div className="input-label">Question</div>
          <input
            type="text"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
          />
        </div>
        <div className="input-box correct-answer">
          <div className="input-label">Correct Answer</div>
          <input
            type="text"
            onChange={(e) => setCorrectAnswer(e.target.value)}
            value={correctAnswer}
          />
        </div>
        <div className="input-box incorrect-answer1">
          <div className="input-label">Incorrect Answer 1</div>
          <input
            type="text"
            onChange={(e) => setIncorrectAnswer1(e.target.value)}
            value={incorrectAnswer1}
          />
        </div>
        <div className="input-box incorrect-answer2">
          <div className="input-label">Incorrect Answer 2</div>
          <input
            type="text"
            onChange={(e) => setIncorrectAnswer2(e.target.value)}
            value={incorrectAnswer2}
          />
        </div>
        <div className="input-box incorrect-answer3">
          <div className="input-label">Incorrect Answer 3</div>
          <input
            type="text"
            onChange={(e) => setIncorrectAnswer3(e.target.value)}
            value={incorrectAnswer3}
          />
        </div>
        <div className="bottom-section">
          <div className="bottom-inputs">
            <div className="input-box input-bottom category">
              <div className="input-label">Category</div>
              <select
                value={category}
                id="category"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option />
                {categories.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="input-box input-bottom difficulty">
              <div className="input-label">Difficulty</div>
              <select
                value={difficulty}
                id="difficulty"
                onChange={(e) => setDifficulty(e.target.value)}
              >
                {difficulties.map((item) => (
                  <option value={item}>{item}</option>
                ))}
              </select>
            </div>
            <div className="input-box input-bottom expiration">
              <div className="input-label">Expiration</div>
              <input
                type="checkbox"
                name="expiration-check"
                id="expiration-check"
                onChange={toggleExpiration}
                checked={expiration}
              />
              {expiration && (
                <input
                  type="date"
                  name="expiration-date"
                  id="expiration-date"
                  onChange={(e) => updateExpiration(e)}
                />
              )}
            </div>
          </div>
          {view === "addQuestion" ? (
            <div className="input-box bottom-buttons">
              <input className="form-btn" type="submit" value="Submit"></input>
              <button
                className="form-btn"
                type="button"
                onClick={rejectQuestion}
              >
                Reject
              </button>
              <button
                className="form-btn"
                type="button"
                onClick={stashQuestion}
              >
                Stash
              </button>
            </div>
          ) : (
            <div className="input-box bottom-buttons">
              <button
                className="form-btn"
                type="button"
                onClick={updateQuestion}
              >
                Save
              </button>
              <button
                className="form-btn"
                type="button"
                onClick={deleteQuestion}
              >
                Delete
              </button>
              <button
                className="form-btn"
                type="button"
                onClick={stashQuestion}
              >
                Stash
              </button>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default NewQuestionForm;
